import React, { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { Color } from "./Colors";

const SeverityColorMapping = {
  info: Color.White,
  success: Color.Green,
  error: Color.Red,
};

// Slide up animation
const slideUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Slide down animation
const slideDown = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(20px);
    opacity: 0;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.open ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 9998;
  transition: background-color 0.3s ease-in-out;
`;

const ModalWrapper = styled.div`
  position: fixed;
  transform: ${(props) =>
    props.open
      ? "translate(-50%, -50%)"
      : "translate(-50%, -50%) translateY(16px)"};
  opacity: ${(props) => (props.open ? 1 : 0)};
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  box-shadow: 0 0px 32px 0 ${(props) => SeverityColorMapping?.[props.severity]}; /* Changed to white glow */
  backdrop-filter: blur(8px); /* Increased blur for more 'glassiness' */
  transition: all 0.3s ease-in-out; /* Smooth out any changes */

  color: ${Color.Green};
  z-index: 9999;
  //transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, top 0.3s ease-in-out;
  max-width: 720px;
  width: 80vw;
  display: flex;
  flex-direction: column;
  animation: ${(props) => (props.open ? slideUp : slideDown)} 0.3s ease-in-out
    forwards;
`;

const Header = styled.div`
  padding: 16px;
  color: ${(props) => SeverityColorMapping?.[props.severity]};

  h2 {
    margin: 0;
  }
`;

const Body = styled.div`
  padding: 32px 16px;
  color: #fff;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 8px;
  right: 15px;
  cursor: pointer;
  color: #ffffff;
  font-size: 1.2rem;
`;

const Modal = ({ open, severity, onClose, title, children }) => {
  const modalRef = useRef();

  const handleOverlayClick = (e) => {
    if (modalRef.current === e.target) onClose();
  };

  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape" && open) {
        onClose();
      }
    };
    window.addEventListener("keydown", handleEscKey);
    return () => {
      window.removeEventListener("keydown", handleEscKey);
    };
  }, [open, onClose]);

  return (
    <Overlay open={open} onClick={handleOverlayClick} ref={modalRef}>
      <ModalWrapper open={open} severity={severity}>
        <CloseButton onClick={onClose}>&#10005;</CloseButton>
        <Header severity={severity}>
          <h2>{title}</h2>
        </Header>
        <Body>{children}</Body>
      </ModalWrapper>
    </Overlay>
  );
};

export default Modal;
