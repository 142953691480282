import { useEffect, useState } from "react";
import { FancyButton, FancyIconButton, truncateEthAddress } from "./Gallery/GalleryStyles";
import { styled } from "styled-components";
import { Color } from "../Colors";
import { FaBars } from "react-icons/fa";
import { SiOpensea, SiTwitter } from "react-icons/si";
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount } from "wagmi";
import { disconnect } from "wagmi/actions";
import {GrLogout} from 'react-icons/gr'
import {RiLogoutBoxRLine} from "react-icons/ri"
export function Header({
  activeTab,
  viewAiorbits,
  viewAibolts,
  viewHome,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const { openConnectModal } = useConnectModal();
  const { address } = useAccount()

  const toggleMenu = () => setIsOpen(!isOpen);

  const closeMenu = () => setIsOpen(false);
  const closeAfterOperation = (fn) => {
    fn();
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 60) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <HeaderContainer isScrolled={isScrolled}>
      <Navbar>
        <NavbarContainer>
          <Logo
            style={activeTab == "home" ? { color: Color.Green } : {}}
            onClick={() => closeAfterOperation(viewHome)}
          >
            HOME
          </Logo>
          <MenuIcon onClick={toggleMenu}>
            <FaBars />
          </MenuIcon>
          <Menu isOpen={isOpen}>
            {!!address && (
              <>
                <MenuItem>
                  <NavLink
                    onClick={() => closeAfterOperation(viewAiorbits)}
                    style={
                      activeTab == "aiorbits" ? { color: Color.Green } : {}
                    }
                  >
                    AIORBIT
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    onClick={() => closeAfterOperation(viewAibolts)}
                    style={activeTab == "aibolts" ? { color: Color.Green } : {}}
                  >
                    AIBOLT
                  </NavLink>
                </MenuItem>
              </>
            )}
            <MenuItem>
              <a
                href="https://twitter.com/aiorbitnft"
                target="_blank"
                rel="noreferrer noopener"
                style={{ textDecoration: "none", color: "white" }}
              >
                <SiTwitter />
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="https://opensea.io/collection/aiorbitnft"
                target="_blank"
                rel="noreferrer noopener"
                style={{ textDecoration: "none", color: "white" }}
              >
                <SiOpensea />
              </a>
            </MenuItem>
            <MenuItem>
              <div style={{ display: 'flex' }}>
                <FancyButton
                  onClick={openConnectModal}
                  buttonColor={!!address ? "#39FF14" : "#fff"}
                  disabled={!!address}
                >
                  {!!!address && "Connect Wallet"}
                  {truncateEthAddress(address)}
                </FancyButton>
                {!!address && 
                <FancyIconButton 
                    buttonColor={"#fff"}
                    onClick={async () => {
                      await disconnect()
                      closeAfterOperation(viewHome)
                    }}>
                  <RiLogoutBoxRLine color="inherit" />
                </FancyIconButton>}
              </div>
            </MenuItem>
          </Menu>
        </NavbarContainer>
      </Navbar>
    </HeaderContainer>
  );
}

export default Header;

const HeaderContainer = styled.div`
  backdrop-filter: ${({ isScrolled }) =>
    isScrolled ? "blur(4.20px)" : "none"};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: fixed;
  padding: 24px 3%;
  top: 0;
  width: 100%;
  z-index: 9999;
  transition: all 0.3s ease-in-out;

  * {
    transition: all 0.3s ease-in-out;
  }
`;

const Navbar = styled.nav`
  width: 100%;
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 840px) {
    align-items: flex-start;
  }
`;

const Logo = styled.a`
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  margin-right: auto;

  &:hover {
    cursor: pointer;
  }
`;

const CenterIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Menu = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  left: 0;

  @media screen and (max-width: 840px) {
    flex-direction: column;
    position: absolute;
    top: 70px;
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    opacity: ${({ isOpen }) => (isOpen ? "100%" : "0%")};
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
    transition: all 0.3s ease-in-out;
    height: 100vh;
    justify-content: flex-start;
  }
`;

const MenuIcon = styled.div`
  font-size: 24px;
  color: white;
  cursor: pointer;
  display: none;

  @media screen and (max-width: 840px) {
    display: block;
  }
`;

const MenuItem = styled.li`
  margin: 0 15px;

  &:disabled {
    color: ${Color.Green};
    pointer-events: none;
  }

  @media screen and (max-width: 840px) {
    margin: 10px 0;
    padding: 8px 0;
  }
`;

const NavLink = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-decoration: none;

  &:hover {
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
  }

  &:disabled {
    color: ${Color.Green};
    pointer-events: none;
  }
`;
