
// Mainnet

/* 
export const NETWORK = 'eth'
export const AIORBIT_CONTRACT_ADDRESS = '0xba66a7c5e1f89a542e3108e3df155a9bf41ac824'
export const AIBOLT_CONTRACT_ADDRESS = '0x1fae24880b10b3bf6aecbddf0754cbbc1aa6dae2'
*/


// Testnet - sepolia

const NETWORK = process.env.REACT_APP_NETWORK
const AIORBIT_CONTRACT_ADDRESS = process.env.REACT_APP_AIORBIT_CONTRACT_ADDRESS
const AIBOLT_CONTRACT_ADDRESS = process.env.REACT_APP_AIBOLT_CONTRACT_ADDRESS
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export { AIBOLT_CONTRACT_ADDRESS, AIORBIT_CONTRACT_ADDRESS, NETWORK, BACKEND_URL }