import { Box, Tab, Tabs } from "@mui/material";
import { Color } from "../../Colors";
import styled, { css } from 'styled-components';

export function truncateEthAddress(address = "") {
  return !!address ? `${address.slice(0, 6)}...${address.slice(39)}` : "";
}

export const GalleryMainContainer = styled.div`
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  color: white;
  background: transparent; /* Add this line */
`;

export const closeButtonNftCardStyle = {
  zIndex: 10,
  position: "absolute",
  top: "-10px",
  right: "-10px",
  padding: "5px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  background: "#ff4d4d", // Slightly softer red
  width: "20px",
  height: "20px",
  color: "white",
  border: "1px solid rgba( 255, 255, 255, 0.18 )", // Introduced a subtle border
  cursor: "pointer",
  boxShadow: "0 2px 5px 0 rgba( 255, 255, 255, 0.37 )", // Introduced a subtle white glow
  transition: "all 0.3s ease-in-out", // Smooth out any changes
  "&:hover": {
    // Introduced hover state for better user interaction
    background: "#e33e3e",
  },
};
export const NFTCard = styled.div`
  color: white;
  overflow: hidden;
  text-align: center;
  background: #000000;
  backdrop-filter: blur(8.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  > img {
    width: 100%;

    &:hover {
      cursor: pointer;
      border: 0;
    }
  }

  ${props => (props.hover || props.hover === undefined) && css`
    &:hover {
      cursor: pointer;
      border: 0;
      box-shadow: 0 0px 32px 0 rgba(255, 255, 255, 0.37); /* Changed to white glow */
      backdrop-filter: blur(8px); /* Increased blur for more 'glassiness' */
      border: 1px solid rgba(255, 255, 255, 0.18);
      transition: all 0.3s ease-in-out; /* Smooth out any changes */
    }
  `}
`;

export const OrbitSearchBarStyle = styled.input`
  background: none;
  border: 1px solid white;
  padding: 8px 12px;
  outline: none;
  color: white;
  font-family: "Space Mono", "Poppins", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto";

  h2 {
    margin: 16px 0;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const actionsStyle = {
  display: "flex",
  justifyContent: "flex-end",
  gap: "16px",
  padding: "16px",
  flexWrap: "wrap",
  color: "black",
};

export const Toggle = styled(Tabs)`
  && {
    display: flex;
    align-items: center;
    background: none;
    color: white;
    jusify-contents: flex-start;
    margin-top: 24px;
    width: 100%;

    .MuiButtonBase-root {
      font-size: 16px;
      font-weight: bold;

      &:hover {
        background: rgba(0, 0, 0, 0.75);
        border-radius: 6px;
      }
    }

    .MuiTab-root {
      font-family: "Space Mono", "Poppins", -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto";
      color: white;
      border: 3px solid transparent;
      transition: color 0.3s ease, border-color 0.3s ease, font-weight 0.3s ease;
    }

    .Mui-selected {
      color: ${Color.Green};
      font-weight: bold;
      transition: color 0.3s ease, border-color 0.3s ease, font-weight 0.3s ease;

      &:hover {
        background: none;
        pointer-evennts: none;
      }
    }

    .MuiTouchRipple-root:after {
      filter: brightness(0) saturate(100%) invert(38%) sepia(92%) saturate(609%)
        hue-rotate(98deg) brightness(104%) contrast(103%);
    }
  }
`;

export const StyledIconButton = styled.button.attrs((props) => ({
  buttonColor: props?.buttonColor || "#fff",
}))`
  all: unset;
  font-size: 16px;
  padding: 8px 12px;
  color: ${(props) => props?.buttonColor};
  text-align: center;
  width: fit-content;
  background: none;
  outline: none;
  border: 0;
  display: flex;
  transition: all 0.5s;
  align-items: center;
  justify-content: space-between;

  &:hover {
    color: ${(props) => (props?.buttonColor === "#fff" ? "#000" : "#fff")};
    background: ${(props) => props?.buttonColor};
    cursor: pointer;
  }

  &:disabled {
    pointer-events: none;
  }
`;

export function FancyIconButton({ buttonColor, onClick, disabled, children }) {
  return (
    <StyledIconButton
      buttonColor={buttonColor}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </StyledIconButton>
  );
}


export const StyledButton = styled.button.attrs((props) => ({
  buttonColor: props?.buttonColor || "#fff",
}))`
  all: unset;
  font-size: 16px;
  padding: 8px 12px;
  color: ${(props) => props?.buttonColor};
  text-align: center;
  width: fit-content;
  background: none;
  outline: none;
  border: 1px solid ${(props) => props?.buttonColor};
  display: flex;
  transition: background 0.5s ease;
  align-items: center;
  justify-content: space-between;

  &:hover {
    color: ${(props) => (props?.buttonColor === "#fff" ? "black" : "#fff")};
    cursor: pointer;
    background: ${(props) => props?.buttonColor};
  }

  &:disabled {
    pointer-events: none;
  }
`;

export function FancyButton({ buttonColor, onClick, disabled, children }) {
  return (
    <StyledButton
      buttonColor={buttonColor}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
}

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 16px;
  margin: 0 auto;
  width: 100%;
  padding: 24px 0;
`;

export const CenterFlex = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  flex-wrap: wrap;
`;

export const HeaderRibbon = styled.p`
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0.5;
  color: #fff;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: monospace;
  bottom: 20px;
`;

export const PaginationButton = styled.button`
  padding: 10px 20px; // Increase the horizontal padding
  margin: 0 5px; // Add a small margin to separate the buttons from the text
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  :hover {
    color: #ffbc42;
  }
`;

export const PaginationText = styled.h1`
  color: #fff;
  margin: 0 10px;
  font-size: 20px;
`;
