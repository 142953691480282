import React, { useMemo } from "react";
import { GridContainer, NFTCard } from "./GalleryStyles.js";
import { Color } from "../../Colors";

const NFTGrid = ({
  allNFTs,
  nftTab,
  searchTokensQuery,
  orbitOffset,
  maxItemsPerPage,
  handleAddToken,
  handleForge,
  startForging,
  selectedTokenIdsArray,
  setOpenOrbitCardParams,
  openOrbitCardParams,
}) => {
  // Memoize computation heavy operations
  const filteredNFTs = useMemo(() => {
    return allNFTs?.[nftTab]?.filter((item) =>
      item?.tokenId?.includes(searchTokensQuery)
    );
  }, [allNFTs, nftTab, searchTokensQuery]);

  const slicedNFTs = useMemo(() => {
    return filteredNFTs?.slice(orbitOffset, orbitOffset + maxItemsPerPage);
  }, [filteredNFTs, orbitOffset, maxItemsPerPage]);

  return (
    <GridContainer>
      {slicedNFTs?.map((item) => (
        <NFTCard
          key={item?.tokenId}
          onClick={() => {
            if (!startForging) {
              if (item?.collectionName === "aibolts") {
                setOpenOrbitCardParams({
                  open: true,
                  onClose: () =>
                    setOpenOrbitCardParams({
                      ...openOrbitCardParams,
                      open: false,
                    }),
                  orbit: item,
                  forge: () => {
                    handleAddToken(item);
                    handleForge("aiorbits");
                  },
                });
              } else {
                setOpenOrbitCardParams({
                  open: true,
                  onClose: () =>
                    setOpenOrbitCardParams({
                      ...openOrbitCardParams,
                      open: false,
                    }),
                  orbit: item,
                });
              }
            } else {
              handleAddToken(item);
            }
          }}
          style={
            selectedTokenIdsArray[nftTab]?.includes(item?.tokenId)
              ? { border: `1px solid ${Color.Green}` }
              : {}
          }
        >
          <img src={item?.metadata?.image} alt={item?.tokenId} />
          <p>{item?.tokenId}</p>
        </NFTCard>
      ))}
    </GridContainer>
  );
};

export default NFTGrid;
