import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import merge from 'lodash.merge'

import '@rainbow-me/rainbowkit/styles.css';

import {
  midnightTheme,
  RainbowKitProvider,
  connectorsForWallets,
} from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  metaMaskWallet,
  coinbaseWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  sepolia,
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { QueryClient, QueryClientProvider } from "react-query";
import { Color } from "./Colors";


const myTheme = merge(midnightTheme(), {
  colors: {
    accentColor: 'white',
    accentColorForeground: 'black',
    connectButtonBackground: 'none',
    connectButtonText: 'white',
    connectButtonInnerBackground: 'none',
    actionButtonBorder: 'white',
    modalBorder: 'white',
    error: Color.Red,
    modalTextSecondary: Color.White,
  },
  radii: {
    modal: 0,
    connectButton: 0,
    actionButton: 0,
    menuButton: 0
  },
  fonts: {
    body: 'Space Mono'
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const { chains, publicClient } = configureChains(
  [mainnet, sepolia],
  [
    alchemyProvider({ apiKey: 'jLyYiNT_cmkNwr2HyvW1w2J815frVq1k' }),
    publicProvider(),
  ]
);

const projectId= 'db1be03b9e357d76eceae277c03db2bc'

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({ projectId, chains }),
      injectedWallet({ projectId, chains }),
      coinbaseWallet({projectId, chains}),
    ],
  }
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})

root.render(
  <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains} theme={myTheme} modalSize="compact">
      <App />
    </RainbowKitProvider>
    </WagmiConfig>

    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
