import React from "react";
import Gallery from "./components/Gallery/Gallery";
import SolarSystem from "./components/SolarSystem";
import "./App.css";
import TabProvider from "./TabProvider";

function App() {
  return (
    <TabProvider>
      <div style={{ width: "100%", height: "100%" }}>
        <Gallery />
        <div className="solar-system">
          <SolarSystem/>
        </div>
      </div>
    </TabProvider>
  );
}

export default App;
