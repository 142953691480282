import * as d3 from "d3";
import React, { useEffect, useRef, useState, useCallback } from "react";
import "./SolarSystem.css";
import { v4 as uuid } from "uuid";
import Name from "./Name";
import { ReactComponent as SolarSystemSVG } from "../assets/SolarSystem.svg";
import io from "socket.io-client";
import { BACKEND_URL } from "../Constants";
import useRateLimit from "../helpers/utils";
import useTab from "../hooks/useTab";
import useSocket from "../hooks/useSocket";
import styled from "@emotion/styled";
import useGetAccountAddress from "../hooks/useGetAccountAddress";

const SolarSystem = () => {
  const {
    data: authenticatedUser,
    isLoading: isLoadingUser,
    mutate: loadUser,
  } = useGetAccountAddress();
  const { nftTab, setNftTab } = useTab();
  useEffect(() => {
    loadUser();
  }, []);

  const InfoTextBox = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 5%;
    padding: 16px;

    @media (max-width: 768px) {
      flex-direction: column; // Stack boxes vertically
      align-items: center;
      height: 80%;
      overflow: auto;
      position: relative;
    }
  `;

  const SubInfoBox = styled.div`
    text-align: center;
    color: white;
    background: #000000;
    backdrop-filter: blur(8.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 8px;
    margin: 0 8px;
    box-shadow: 0 2px 5px 0 rgba(255, 255, 255, 0.37);
    transition: all 0.3s ease-in-out;

    @media (max-width: 768px) {
      margin: 8px 0; // Add vertical margins in mobile view
    }
  `;

  return (
    <div className={`svg-container`}>
      <SolarSystemSVG width="1024" height="1024" />

      {nftTab === "home" && (
        <InfoTextBox>
          <SubInfoBox>
            🚀 <strong>What is AIBOLT?</strong>
            <br />
            Embark on a cosmic journey with AIBOLT, your storytelling NFT & AI
            companion. 🌌 Stack Event NFTs on AIBOLT realms to mark your voyage!
            🌠 Part of @aiorbitnft.
          </SubInfoBox>
          <SubInfoBox>
            🤖 <strong>What is AIORBIT?</strong>
            <br />A unique ecosystem where NFTs and AI collide. 💥 AIBOLT is a
            star in this universe, serving as both your AI pal and upgradeable
            on-chain NFT. 🌟
          </SubInfoBox>
          <SubInfoBox>
            🖥️ <strong>How to Use This Site?</strong>
            <br />
            Connect your wallet 🏦, browse the gallery 🖼️. AIORBIT & AIBOLT are
            building a creator ecosystem on the crossroads of AI and Blockchain.
            🛠️
          </SubInfoBox>
        </InfoTextBox>
      )}
    </div>
  );
};

export default SolarSystem;
