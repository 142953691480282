import { ethers } from 'ethers';
import React from 'react'
import { useMutation } from 'react-query';
import { NETWORK } from '../Constants';

export default function useGetAccountAddress() {

  const {
    data,
    isLoading,
    error,
    isError,
    mutate,
  } = useMutation(['get-wallet-address'], async () => {
    try {
      if (typeof window.ethereum !== 'undefined') {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
        const provider = new ethers.providers.Web3Provider(window?.ethereum, "any");
        // const signer = provider.getSigner();
        const net = await provider.getNetwork();

        return {
          accountAddress: accounts[0],
          network: net.name
        }
      }
      else {
        const err = new Error("Non-ethereum browser detected. Install metamask and try again.")
        err.code = "NO_METAMASK"
        throw err
      }
    } catch (err) {
      switch (err.code) {
        case -32002:
          throw new Error("Aleady requested Eth address. Click the metamask button on your browser to connect wallet.")
        case "NO_METAMASK":
          throw new Error(err.message)
        default:
          throw new Error("Unexpected Error.")
      }
    }
  }, {
  })
  return {
    data,
    isLoading,
    error: error?.message,
    isError,
    mutate
  }
}