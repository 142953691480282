import { AiOutlineClose } from "react-icons/ai";
import {
  CenterFlex,
  FancyButton,
  NFTCard,
  actionsStyle,
  closeButtonNftCardStyle,
} from "./GalleryStyles";
import { Color } from "../../Colors";

export const TokenList = ({
  forge,
  forgingNotHappenedYet,
  forgingParametersValid,
  selectedTokensArray,
  setOpenModal,
  startForging,
  openModal,
  resetForgeQuery,
  handleRemoveToken,
  handleCancelForging,
}) => (
  <div
    style={{
      display: "flex",
      gap: "10px",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      padding: "12px 0",
      overflow: "auto",
    }}
  >
    {selectedTokensArray
      ?.sort((a, b) => a.tokenId - b.tokenId)
      ?.map((item) => (
        <div
          key={`${item?.collectionName}-${item?.tokenId}`}
          style={{ position: "relative" }}
        >
          <>
            <button
              style={closeButtonNftCardStyle}
              onClick={() =>
                handleRemoveToken(item?.tokenId, item?.collectionName)
              } // Replace handleRemoveToken with the appropriate function
            >
              <AiOutlineClose />
            </button>
            <NFTCard hover={false}>
              <div
                style={{ width: "80px" }}
                dangerouslySetInnerHTML={{
                  __html: atob(
                    item?.metadata?.image?.replace(
                      "data:image/svg+xml;base64,",
                      ""
                    )
                  ),
                }}
              />
              <p>{item?.tokenId}</p>
            </NFTCard>
          </>
        </div>
      ))}
    {!!forgingParametersValid() && (
      <FancyButton
        buttonColor={Color.Green}
        disabled={!forgingParametersValid()}
        onClick={() => {
          if (forgingParametersValid()) {
            setOpenModal({
              open: true,
              onClose: () => {
                resetForgeQuery();
                setOpenModal({ ...openModal, open: false });
              },
              severity: "info",
              title: "Forge Menu",
              children: (
                <>
                  {!!forgingNotHappenedYet && (
                    <div>
                      <CenterFlex>
                        {selectedTokensArray
                          ?.sort((a, b) => a.tokenId - b.tokenId)
                          ?.map((item) => (
                            <div
                              key={`${item?.collectionName}-${item?.tokenId}`}
                              style={{ position: "relative", maxWidth: "100px" }}
                            >
                              <>
                                <button
                                  style={closeButtonNftCardStyle}
                                  onClick={() =>
                                    handleRemoveToken(
                                      item?.tokenId,
                                      item?.collectionName
                                    )
                                  } // Replace handleRemoveToken with the appropriate function
                                >
                                  <AiOutlineClose />
                                </button>
                                <NFTCard hover={false}>
                                  <img
                                    src={item?.metadata?.image}
                                    alt={item?.tokenId}
                                  />
                                  <p>{item?.tokenId}</p>
                                </NFTCard>
                              </>
                            </div>
                          ))}
                      </CenterFlex>
                      <div style={actionsStyle}>
                        <FancyButton
                          onClick={() => {
                            const orbits = selectedTokensArray
                              ?.filter(
                                (item) => item.collectionName === "aiorbits"
                              )
                              ?.map((item) => +item.tokenId);
                            const bolt = selectedTokensArray
                              ?.filter(
                                (item) => item.collectionName === "aibolts"
                              )
                              ?.map((item) => item.tokenId)?.[0];
                            forge([orbits, bolt]);
                          }}
                          buttonColor={Color.Green}
                        >
                          Forge
                        </FancyButton>
                        <FancyButton
                          onClick={() => () => {
                            setOpenModal({ ...openModal, open: false });
                            resetForgeQuery();
                          }}
                          disabled={false}
                        >
                          Cancel
                        </FancyButton>
                      </div>
                    </div>
                  )}
                </>
              ),
            });
          } else {
            setOpenModal({
              open: true,
              onClose: () => {
                resetForgeQuery();
                setOpenModal({ ...openModal, open: false });
              },
              severity: "error",
              title: "Error",
              children: <>Forging not allowed.</>,
            });
          }
        }}
      >
        Forge
      </FancyButton>
    )}
    {!!startForging && (
      <FancyButton onClick={handleCancelForging}>Clear</FancyButton>
    )}
  </div>
);
