import React, { useState, useCallback } from "react";

export default function usePage(items, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(0);

  const totalPages = Math.ceil(items?.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((prevPageNumber) =>
      Math.min(prevPageNumber + 1, totalPages - 1)
    );
  };

  const prevPage = () => {
    setCurrentPage((prevPageNumber) => Math.max(prevPageNumber - 1, 0));
  };

  const refetchPages = (newLength) => {
    const newTotalPages = Math.ceil(newLength / itemsPerPage);
    if (currentPage >= newTotalPages) {
      setCurrentPage(newTotalPages - 1);
    }
  };

  return { currentPage, nextPage, prevPage, totalPages, refetchPages };
}
