// TabProvider.js
import React, { useState } from 'react';
import TabContext from './TabContext';

const TabProvider = ({ children }) => {
  const [nftTab, setNftTab] = useState('home');

  return (
    <TabContext.Provider value={{ nftTab, setNftTab }}>
      {children}
    </TabContext.Provider>
  );
};

export default TabProvider;
