import axios from 'axios';
import { useQuery } from 'react-query';
import { AIBOLT_CONTRACT_ADDRESS, AIORBIT_CONTRACT_ADDRESS, BACKEND_URL, NETWORK } from '../Constants';


export default function useGetNFTs(walletAddress) {
    const {
        data,
        isLoading,
        isError,
        isRefetching,
        isRefetchingError,
        refetch
    } = useQuery(['get-nfts-of-wallet', walletAddress], async () => {
        const response = await axios.get(`${BACKEND_URL}/nfts/get/${walletAddress}`, {
            params: {
                network: NETWORK,
                orbitsContract: AIORBIT_CONTRACT_ADDRESS,
                boltContract: AIBOLT_CONTRACT_ADDRESS,
            }
        })
        console.log(response?.data)
        return response?.data

    },
        {
            enabled: !!walletAddress
        });

    // console.log("Count", data?.length)

    return {
        data,
        isLoading,
        isError,
        isRefetching,
        isRefetchingError,
        refetch
    }
}