import { useState, useEffect } from "react";
import "./Gallery.css";
import "../Header.css";
import useGetNFTs from "../../hooks/useGetNFTs";
import Modal from "../../Modal";
import { Color } from "../../Colors";
import { Box, Typography } from "@mui/material";
import forgeOrbits from "../../helpers/forgeOrbits";
import { useMutation } from "react-query";
import LoadingSpinner from "../LoadingSpinner";
import {
  truncateEthAddress,
  actionsStyle,
  FancyButton,
  HeaderRibbon,
  GalleryMainContainer,
  PaginationContainer,
  PaginationButton,
  PaginationText,
} from "./GalleryStyles.js";
import OrbitCard from "../../OrbitCard";
import ScrollToTopButton from "../ScrollToTop";
import usePage from "../../hooks/usePage";
import GalleryHome from "./GalleryHome";
import Header from "../Header";
import { TokenList } from "./TokenList";
import useTab from "../../hooks/useTab";
import NFTGrid from "./NFTGrid";
import { useAccount, useNetwork } from "wagmi";

const MAX_ITEMS_PER_PAGE = 10; // Change this to increase/decrease page size

const Gallery = () => {
  const [forgingTokenId, setForgingTokenId] = useState(null);
  const maxItemsPerPage = MAX_ITEMS_PER_PAGE;

  const {address} = useAccount()
  const {chain} = useNetwork()

  const {
    data: allNFTs,
    isLoading: isLoadingAllNFTs,
    refetch: refetchNFTs,
    isRefetching: isRefetchingAllNFTs,
    isError: isErrorAllNFTs,
    isRefetchingError: isRefetchingErrorAllNFTs,
  } = useGetNFTs(address);

  useEffect(() => {
    if (allNFTs && forgingTokenId) {
      const bolt = allNFTs?.["aibolts"]?.filter(
        (item) => item?.tokenId === forgingTokenId
      )?.[0];
      if (bolt) {
        setForgingTokenId(null);
        // proceed with setting modal and other necessary state updates
        handleCancelForging();
        setNftTab("aibolts");
        setSelectedTokenIdsArray({
          aiorbits: [],
          aibolts: [],
        });
        setSelectedTokensArray([]);
        const bolt = allNFTs?.["aibolts"]?.filter(
          (item) => item?.tokenId === forgingTokenId
        )?.[0];
        setOpenModal({
          ...openModal,
          title: "Success Forge",
          severity: "success",
          children: (
            <>
              Successfully forged Bolt #{bolt?.tokenId}.
              <div style={actionsStyle}>
                <FancyButton
                  onClick={async () => {
                    setOpenOrbitCardParams({
                      open: true,
                      onClose: () => setOpenOrbitCardParams(false),
                      orbit: bolt,
                    });
                    setOpenModal({ ...openModal, open: false });
                  }}
                >
                  Show
                </FancyButton>
              </div>
            </>
          ),
        });
      }
    }
  }, [allNFTs]);

  const {
    isIdle: forgingNotHappenedYet,
    reset: resetForgeQuery,
    mutate: forge,
  } = useMutation(
    ["forge-orbits"],
    async (params) => {
      const [orbits, aiboltId] = params;
      setOpenModal({
        ...openModal,
        title: "Forging in Progress",
        children: (
          <div>
            <LoadingSpinner />
            <p>Forging the orbits</p>

            <div style={actionsStyle}></div>
          </div>
        ),
      });
      return await forgeOrbits(orbits, aiboltId);
    },
    {
      onSuccess: async (boltId) => {
        console.log("on success");
        setForgingTokenId(boltId);
        await refetchNFTs();
      },
      onError: (err) => {
        setOpenModal({
          ...openModal,
          title: "Forging Failed",
          children: <>{err?.message}</>,
          severity: "error",
        });
      },
    }
  );

  function handleRemoveToken(tokenId, collectionName) {
    const newSelected = selectedTokenIdsArray;
    newSelected[collectionName] = newSelected?.[collectionName]?.filter(
      (item) => item !== tokenId
    );
    setSelectedTokenIdsArray(newSelected);
    setSelectedTokensArray(
      selectedTokensArray?.filter(
        (item) =>
          !(
            item?.tokenId === tokenId && item?.collectionName === collectionName
          )
      )
    );
  }

  // If item is Orbit, should be a max of 5
  function handleAddToken(item) {
    const { tokenId, collectionName } = item;

    const modalConfig = {
      open: false,
      onClose: () => setOpenModal({ ...openModal, open: false }),
      severity: "error",
      title: "Error",
      children: <>{"Error selecting so many items"}</>,
    };

    if (selectedTokenIdsArray?.[collectionName]?.includes(tokenId)) {
      handleRemoveToken(tokenId, collectionName);
      return;
    }

    if (
      collectionName === "aiorbits" &&
      selectedTokenIdsArray?.["aiorbits"]?.length >= 5
    ) {
      setOpenModal({
        ...modalConfig,
        open: true,
        children: <>Cannot select more than 5 orbits.</>,
      });
      return;
    }

    if (
      collectionName === "aibolts" &&
      selectedTokenIdsArray?.["aibolts"]?.length >= 1
    ) {
      setOpenModal({
        ...modalConfig,
        open: true,
        children: (
          <>
            Can only select 1 aibolt. Remove the selected one in order to
            proceed further.
          </>
        ),
      });
      return;
    }

    setSelectedTokensArray([...selectedTokensArray, { ...item, type: nftTab }]);
    const newSelected = selectedTokenIdsArray;
    newSelected[collectionName] = [...newSelected[collectionName], tokenId];
    setSelectedTokenIdsArray(newSelected);
  }

  function handleTabChange(event, newValue) {
    setNftTab(newValue);
  }


  const [selectedTokenIdsArray, setSelectedTokenIdsArray] = useState({
    aiorbits: [],
    aibolts: [],
  });
  const [selectedTokensArray, setSelectedTokensArray] = useState([]);
  const { nftTab, setNftTab } = useTab("home");
  const [searchTokensQuery, setSearchTokensQuery] = useState("");

  const { currentPage, prevPage, nextPage, totalPages, refetchPages } = usePage(
    allNFTs?.[nftTab],
    maxItemsPerPage
  );

  const [openModal, setOpenModal] = useState({
    open: false,
    onClose: () => setOpenModal({ ...openModal, open: false }),
    severity: "error",
    title: "",
    children: <>{""}</>,
  });

  const [openOrbitCardParams, setOpenOrbitCardParams] = useState({
    open: false,
  });
  const [startForging, setStartForging] = useState(false);

  const [orbitOffset, setOrbitOffset] = useState(0);

  useEffect(() => {
    refetchPages(allNFTs?.[nftTab]?.length);
    setOrbitOffset(0);
  }, [nftTab, allNFTs]);

  function forgingParametersValid() {
    return (
      selectedTokenIdsArray["aiorbits"].length === 5 &&
      selectedTokenIdsArray["aibolts"].length === 1
    );
  }

  function handleCancelForging() {
    setSelectedTokenIdsArray({
      aiorbits: [],
      aibolts: [],
    });
    setSelectedTokensArray([]);
    setStartForging(false);
  }
  function handleForge() {
    setNftTab("aiorbits");
    setStartForging(true);
  }

  useEffect(() => {
    if (selectedTokensArray.length === 0) {
      setStartForging(false);
    }
  }, [selectedTokensArray]);

  return (
    <GalleryMainContainer>
      <Header
        activeTab={nftTab}
        viewAibolts={() => setNftTab("aibolts")}
        viewAiorbits={() => setNftTab("aiorbits")}
        viewHome={() => setNftTab("home")}
      />

      {/* <ScrollToTopButton /> */}
      <Modal {...openModal} />
      <OrbitCard {...openOrbitCardParams} />
      {
      nftTab === "home" ? (
        <div/>
      ) : (
        <Box
          padding={"24px 3%"}
          zIndex="4"
          width="100%"
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          color={Color.White}
        >
          <HeaderRibbon>
            {!!chain &&
              chain.network !== "homestead" &&
              `You are using a testnet (${chain?.name}).`}
          </HeaderRibbon>

          {nftTab === "aibolts" && <h1 style={{ margin: 0 }}>My Bolts</h1>}
          {nftTab === "aiorbits" && <h1 style={{ margin: 0 }}>My Orbits</h1>}
          <p style={{ margin: "15px 5% 15px 5%" }} >Forge an AIBOLT by selecting it, hitting forge, then selecting 5 AIORBITs.</p>
          <TokenList
            openModal={openModal}
            startForging={startForging}
            handleCancelForging={handleCancelForging}
            forgingParametersValid={forgingParametersValid}
            setOpenModal={setOpenModal}
            resetForgeQuery={resetForgeQuery}
            forge={forge}
            forgingNotHappenedYet={forgingNotHappenedYet}
            selectedTokensArray={selectedTokensArray}
            handleRemoveToken={handleRemoveToken}
          />

          {!isLoadingAllNFTs && !isRefetchingAllNFTs && (
            <>
              {allNFTs?.[nftTab]?.filter(
                (item) => !!item?.tokenId?.includes(searchTokensQuery)
              )?.length === 0 && (
                <Box className="root-box">
                  <Typography variant="body1" className="text">
                    Oops, you are out of {nftTab}.
                  </Typography>
                </Box>
              )}
              <NFTGrid
                allNFTs={allNFTs}
                nftTab={nftTab}
                startForging={startForging}
                searchTokensQuery={searchTokensQuery}
                orbitOffset={orbitOffset}
                maxItemsPerPage={maxItemsPerPage}
                handleAddToken={handleAddToken}
                handleForge={handleForge}
                selectedTokenIdsArray={selectedTokenIdsArray}
                setOpenOrbitCardParams={setOpenOrbitCardParams}
                openOrbitCardParams={openOrbitCardParams}
              />
            </>
          )}
          {!isLoadingAllNFTs && !isRefetchingAllNFTs && (
            <div>
              {allNFTs?.length === 0 && (
                <Box className="root-box">
                  <Typography variant="body1" className="text">
                    Oops, you don't have any orbits. Buy some from Opensea.
                  </Typography>
                </Box>
              )}
            </div>
          )}
          {(!!isLoadingAllNFTs || !!isRefetchingAllNFTs) && (
            <Box className="root-box">
              <Typography variant="body1" className="text">
                Loading...
              </Typography>
            </Box>
          )}
          {(!!isErrorAllNFTs || !!isRefetchingErrorAllNFTs) && (
            <Box className="root-box">
              <Typography variant="body1" className="text">
                Some error occured while fetching, try again.
              </Typography>
            </Box>
          )}

          <PaginationContainer>
            <PaginationButton
              disabled={currentPage <= 0}
              onClick={async () => {
                setOrbitOffset(orbitOffset - maxItemsPerPage);
                prevPage();
              }}
            >
              {'<<'}
            </PaginationButton>
            <PaginationText>
              {currentPage + 1}/{totalPages}
            </PaginationText>
            <PaginationButton
              disabled={currentPage >= totalPages - 1}
              onClick={async () => {
                setOrbitOffset(orbitOffset + maxItemsPerPage);
                nextPage();
              }}
            >
              {'>>'}
            </PaginationButton>
          </PaginationContainer>
        </Box>
      )}
    </GalleryMainContainer>
  );
};

export default Gallery;
