import { ethers } from "ethers";
import {
  AIBOLT_CONTRACT_ADDRESS,
  AIORBIT_CONTRACT_ADDRESS,
  BACKEND_URL,
  NETWORK,
} from "../Constants";
import AiboltAbi from "../abi/AiboltAbi.json";
import AiorbitAbi from "../abi/AiorbitAbi.json";
import axios from "axios";
import { getAccount, getPublicClient, getWalletClient, readContract, waitForTransaction, writeContract} from "wagmi/actions"
export default async function forgeOrbits(orbitIds, boltId) {
  try {
    const provider = getPublicClient()
    const signer = await getWalletClient()
    const account = getAccount()

    const walletAddress = signer.account.address

    const isApprovedForForging = await readContract({
      address: AIORBIT_CONTRACT_ADDRESS,
      abi: AiorbitAbi,
      functionName:'isApprovedForAll',
      args: [walletAddress, AIBOLT_CONTRACT_ADDRESS]}
    );

    if (!!!isApprovedForForging) {
      try {

        const {hash} = await writeContract({
          address: AIORBIT_CONTRACT_ADDRESS,
          abi: AiorbitAbi,
          functionName: "setApprovalForAll",
          args: [AIBOLT_CONTRACT_ADDRESS, true],
          account: account.address
        });
  
        await waitForTransaction({hash});

      } catch (error) {
        throw error
      }
    }

    try {
      const { hash } = await writeContract({
        address: AIBOLT_CONTRACT_ADDRESS,
        abi: AiboltAbi,
        functionName: "forge",
        args: [[parseInt(boltId)], orbitIds],
        account: account.address
      });

      await waitForTransaction({hash}) // Wait for the transaction to be mined
    } catch (error) {
      throw error
    }

    try {
      await axios.get(`${BACKEND_URL}/nft/refresh-metadata`, {
        params: {
          network: NETWORK,
          contractAddress: AIBOLT_CONTRACT_ADDRESS,
          tokenId: boltId,
        },
      });
    } catch (err) {
      const error = new Error( "Failed to update metadata" )
      error.code = "METADATA_SYNC_FAIL"
      throw error
    }
    return boltId;
  } catch (error) {
    switch (error.code) {
      case "ACTION_REJECTED":
        throw new Error("User didn't authorized transaction, failed.");

      case "METADATA_SYNC_FAIL":
        throw new Error(error.message)
      case "INVALID_ARGUMENT":
        throw new Error("Send correct number of AIOrbits and AIBolts.");

      case "CALL_EXCEPTION":
        throw new Error("Contract doesn't exist on this network. Change networks and reload.");
      default:
        throw new Error("Unexpected error.");
    }
  }
}
